@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;400;700&display=swap');
:root {
    --primary: #fff1b9;
    --color-titles: #212529;
    --font-body: "Work Sans", sans-serif;
    --font-titles: "Work Sans", sans-serif;
}

body{background-color:white; font-family: var(--font-body);-webkit-appearance: none;}
h1,h2,h3 {font-weight:bold; font-family: var(--font-titles);color: var(--color-titles);}
a, a:hover{color: var(--font-titles); text-decoration:none;}
form .error {
    color: #ff0000;
}
#a_propos{background-image: url('../images/front/a-propos-cover.jpg');background-color: #ffe477;background-size: cover; height:50vh; min-height:300px;}


.badge{font-weight: 500; margin-right:5px}
.badge-light{background-color: var(--primary);}

.bloc_picto_footer{margin-bottom:-80px;border-radius: 20px;}

/* -- btn -- */
.btn{border-width: 2px;border-radius: 40px;padding: .7rem 1.6rem;font-weight: bolder;}
.btn-primary{background-color:#272727;border-color:#272727;}
.btn-primary:hover{background-color:white;color: #272727;border-color:#d4d4d4;}
.btn-primary.disabled, .btn-primary:disabled{background-color:#e9e9e9; border-color:#e9e9e9;color:black}
.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active{background-color:#272727;border-color:#272727;}
.btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .show > .btn-primary.dropdown-toggle:focus{box-shadow: 0 0 0 .2rem rgba(39,39,39,.5)}
/* -- bg -- */
.bg-black{background-color:#272727}
.bg-grey{background-color:#f9f5f2}
.bg-grey2{background-color:#d9d9d9}
.bg-yellow{background-color:var(--primary);}
.ts-control .item, .ts-wrapper.multi .ts-control > div.active {background-color:var(--primary) !important;}

/* -- cadre panier -- */
#cadre_panier, #cart-footer  {position: sticky;position: -webkit-sticky;top: 135px;z-index: 1010;}
#cadre_panier .card-body {overflow-y: scroll;max-height: 60vh;}


/* -- card -- */
.card-header{background-color:white;border:none}
.card-footer{background-color:white;border:none}
.card-img-top{border-top-left-radius: calc(1.2rem - 1px);border-top-right-radius: calc(1.2rem - 1px);}


.cat_all{min-width:160px;}

#container-recherche{position:sticky;top:78px;z-index:1019}


.cursor{cursor: pointer;}

.dropdown{position:static}
.dropdown-item:hover{}
.dropdown-item:active{background-color:var(--primary);color:#272727}

/* -- Caroussel Flickity-- */
.carousel-cell-index-resto{/*width:33%;height:400px;*/ width:300px;height:400px;padding:10px 0;}
.carousel-cell-index-resto:hover{margin-top: -10px;margin-bottom: 10px;transition: margin 0.5s;}
.carousel-cell-index-categories{/*width:16.5%;height:130px;*/ width:150px;height:150px;}
.flickity-button{background:none;}
.flickity-prev-next-button.previous {left: -40px;}
.flickity-prev-next-button.next {right: -40px;}

.carousel-control-prev, .carousel-control-next{width:1px;}
.carousel-control-prev-icon{background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' width='20' height='20' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");position:absolute; left: -25px;}
.carousel-control-next-icon{background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' width='20' height='20' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");position:absolute; right: -25px;}
.carousel-control-prev-icon, .carousel-control-next-icon{width: 35px;height: 35px;}

.modal-sm-product {max-width: 300px !important;}

footer{padding-top:80px}
.fil-ariane .text-white a {color:white;}
#cookiedisclaimer{position:fixed;right:15px; bottom:15px;max-width:450px;z-index:1000}


/* -- font-size -- */
.fs70{font-size:70% !important}
.fs100{font-size:100% !important}
.fs120{font-size:120% !important}
.fs130{font-size:130% !important}
.fs140{font-size:140% !important}
.fs150{font-size:150% !important}
.fs180{font-size:180% !important}



.form-control:focus, .btn-primary.focus, .btn-primary:focus{box-shadow: 0 0 0 .2rem rgba(255, 241, 185,.5);border-color: #ced4da;}
.form-control:disabled, .form-control[readonly]{background-color:white;}
.btn-primary.focus, .btn-primary:focus{background-color:#23272b; color:white;}

.img_produit_resto{width:auto;max-width:100%; border-radius: 1.2rem 0 0 1.2rem !important; }
.img_produit_resto_modal{width:auto;max-width:100%; border-radius: 1.2rem 1.2rem 0 0 !important; }


.intl-tel-input{width:100%;}

label {margin-bottom: 0 !important}

input[type="checkbox"], input[type="radio"]{
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 20px;
    height:20px;
    border:1px solid #ced4da;

}

select{
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: url(../images/front/picto-chevron-bottom-sm.jpg) 96% / 20px no-repeat #FFF;
}


input[type="checkbox"]{border-radius:4px; }
input[type="radio"]{border-radius:50%; background-image: url(../images/front/picto-radio-inactif.svg);width: 20px;height: 20px;background-size: cover;border: none;}

input[type="checkbox"]:checked{background-image: url(../images/front/checkbox-on.svg);width: 20px;height: 20px;background-size: cover;border: none;}

input[type="radio"]:checked{
    background-image: url(../images/front/picto-radio-actif.svg);
    width: 20px;
    height: 20px;
    background-size: cover;
    border: none;
}

.image_produit, .image_produit img{height: 130px;max-width: 100%;width: auto;}

.iti{width:100%;}

/* -- Index -- */
/*#recherche_index{background-image: url("../img/fond-jaune-index.jpg");background-color: #ffe477;background-size: cover}
#recherche_index1{background-image2: url("../img/fond-jaune-index.png");background-color: #ffe477;background-position: right -20px; background-repeat: no-repeat;background-size: 600px;}
#recherche_index2{background-image2: url("../img/fond-jaune-index2.png");background-color: #ffe477;background-position: right -20px; background-repeat: no-repeat;background-size: 600px;}*/

.indexcard:hover{margin-top: -20px;margin-bottom: 20px;transition: margin 0.5s;}
.etiquette_nouveau{top:0;left:0;z-index:1000}
.etiquette_heures{bottom:8px;right:8px;z-index:1000}

hr{border-top: 2px solid rgba(0,0,0,.1);}

.list-group-item{border:0; padding: 0.5rem 1.25rem !important;}
.list-group-item span{padding: 4px 10px;font-weight: initial;}
.list-group-item.active{background-color: transparent;}
.list-group-item.active span{background-color:var(--primary) !important;color:black; padding: 4px 10px; border-radius: 50rem !important;}

/* -- megamenu -- */
.megamenu {position: static;}
.megamenu .dropdown-menu { background: none; border: none; width: 100%;}



.modal-dialog{max-width:900px;}
.modal-popin{max-width:500px !important;}
.cart_btn_close{display: none !important;}
.modal-content #cadre_panier #panier .cart_btn_close{display: block !important;}


.nav-link{color: #343a40 !important; border-bottom: 2px solid white;}
.nav-link:hover{border-bottom: 2px solid black;}

.num_page, .page-link {text-align:center;font-weight:bold;font-size:110%; border:none;margin:0.2rem;}
.page-item .page-link{background-color: white !important; color: #272727 !important;font-size: 110%;}
.page-item.active .page-link{background-color: #343a40 !important; color: #fff !important;}

/* -- Rounded -- */
.rounded-lg{border-radius: 1.2rem !important;}
.rounded-lg-bottom{border-radius: 0 0 1.2rem 1.2rem !important;}

#scrolltop{position:fixed;bottom:15px;right:15px;z-index:1030}

.ss-main .ss-multi-selected .ss-values .ss-value, .ss-main .ss-content .ss-list .ss-option:hover{background-color: var(--primary);color:black}
.ss-main{border: 1px solid #dee2e6;padding: .375rem .75rem;font-size: 1rem;font-weight: 400;line-height: 1.5;border-radius: .25rem;}
.ss-main .ss-content.ss-open{z-index:6000;}
.ss-main.is-invalid{border-color: #dc3545;
    padding-right: calc(1.5em + .75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
    background-repeat: no-repeat;
    background-position: center right calc(.375em + .1875rem);
    background-size: calc(.75em + .375rem) calc(.75em + .375rem);}
.ss-multi-selected{border:none !important}
.form-control + .ss-main {height: auto;} .ts-control .item{padding:4px 6px !important; border-radius: 4px !important}


.text-black{color:#272727}
.text-grey{color:#858585}
.text-yellow{color:#ffe577}
.text-yellow-logo{color:#ffd11b}


#topheader {top: 0;position:sticky;background-color:white !important;z-index: 1020; background-color:#6899ff;}



.mes-adresses .form-check { border: 1px solid #dee2e6 !important; padding-left: 1.5rem !important;padding-bottom: 0.7rem !important;padding-top: 0.7rem !important;}

.ss-main .ss-multi-selected .ss-values .ss-value, .ss-main .ss-content .ss-list .ss-option:hover{background-color: var(--primary);color:black}
.ss-main{border: 1px solid #dee2e6;padding: .375rem .75rem;font-size: 1rem;font-weight: 400;line-height: 1.5;border-radius: .25rem;}
.ss-main .ss-content.ss-open{z-index:6000;}
.ss-main.is-invalid{border-color: #dc3545;
    padding-right: calc(1.5em + .75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
    background-repeat: no-repeat;
    background-position: center right calc(.375em + .1875rem);
    background-size: calc(.75em + .375rem) calc(.75em + .375rem);}
.ss-multi-selected{border:none !important}
.form-control + .ss-main {height: auto;} .ts-control .item{padding:4px 6px !important; border-radius: 4px !important}
.ss-single-selected{border:none !important}

.shortcutMenu{position: sticky;top: 78px;z-index: 1019;}

.divShoppingAutocompleteResults{max-height: 50vh; overflow-y: scroll}
.picto-modal-togo{max-height: 250px}
.card-product-display{height:150px !important;}

.bg-search {background-color:var(--primary) !important;}

.pagination {margin-bottom: 0 !important;}
.restaurantHeaderDiv {min-height: 350px;}

@media screen and (max-width:850px){
    #container-recherche{top:55px;}
}

@media screen and (max-width:768px){

    .carousel-cell-index-resto{/*width:100%;height:400px;*/ width:250px;height:350px;}
    .carousel-cell-index-categories{width:130px;height:180px;}
    .flickity-prev-next-button.previous {  left: 0px;}
    .flickity-prev-next-button.next {  right: 0px;}
    .indexcard:hover{margin-bottom: 15px;margin-top: 0px;}
    #topheader{position:initial;padding:0;z-index: 100;}
    #cookiedisclaimer{max-width: inherit;width:100%;right:0px; bottom:0px;}
    .form-control {height: calc(1.2em + .75rem + 2px);}
    #container-recherche{top:0;}
    .shortcutMenu{top: 0px;}
    .picto-modal-togo{max-height: 100px}
    h1{font-size:2rem !important;}
    h2{font-size:1.75rem !important;}
    h3{font-size:1.5rem !important;}
    .card-product-display{height:150px !important;}
    .cat_all { width: 130px; display: inline-block; }
    .card-body-shopping-product{height: 90px;}
    .bg-search {background-color:#f9f5f2 !important;}

}


@media screen and (max-width:1200px) {
    #cadre_panier .card-body {max-height: 45vh;}
}

@media (min-width: 1200px) {
    .container, .container-sm, .container-md, .container-lg, .container-xl {
        max-width: 1300px;
    }
}



.btn-primary-border {border: 1px solid black !important;}
.btn-outline-primary {color: black; border: 1px solid black ;}
.btn-outline-primary:hover{ background-color: black; border-color: black}
.btn-outline-primary.bg-white:hover{color: black !important;}
    /*.btn-outline-primary:hover:not(:disabled) {background-color: black !important;}*/
.btn:focus, .btn.focus {box-shadow: unset !important;}
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {background-color: black}
.btn-outline-primary.disabled, .btn-outline-primary:disabled, .btn-outline-primary:hover:disabled {border-color:#d4d4d4 !important; color:#d4d4d4 !important;background-color:white}
.error {color: #dc3545 !important; font-size:0.8rem;}

.nav-pills .nav-link-menu.active, .nav-pills .show > .nav-link {background-color: black !important; color:white !important;}
.nav-pills .nav-link-menu {border-radius: 15px 15px 0 0; border-bottom: none !important; }

#checkout_phoneNumber1_country{max-width: 150px !important;}
#reservation_request_dateRequest_date{width: calc(50% - 15px); margin-right:15px}
#reservation_request_dateRequest_time{width: calc(50% - 15px); margin-left:15px}

.border-1px-black{border: 1px solid #000 !important;}
.rounded-sm {    border-radius: 0.6rem !important;}